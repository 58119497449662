<template>
  <div>
    <vs-alert
      style="margin-bottom: 20px"
      :active.sync="alert.active1"
      closable
      close-icon="close"
      :title="alert.title"
      :color="alert.color"
      >{{ alert.message }}</vs-alert
    >
    <vs-row>
      <vs-col
        v-if="check_has_permission('viewClinicManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Clinics</div>
          </template>
          <h1 class="count">{{ clinics }}</h1>
        </vs-card>
      </vs-col>
      <vs-col
        v-if="check_has_permission('viewFranchiseManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Franchises</div>
          </template>
          <h1 class="count">{{ franchises }}</h1>
        </vs-card>
      </vs-col>
      <vs-col
        v-if="check_has_permission('viewDoctorClinicManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Doctor Clinics</div>
          </template>
          <h1 class="count">{{ franchises }}</h1>
        </vs-card>
      </vs-col>
      <vs-col
        v-if="check_has_permission('viewDoctorManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Doctors</div>
          </template>
          <h1 class="count">{{ doctors }}</h1>
        </vs-card>
      </vs-col>
      <vs-col
        v-if="check_has_permission('viewNurseManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Nurses</div>
          </template>
          <h1 class="count">{{ nurses }}</h1>
        </vs-card>
      </vs-col>
      <vs-col
        v-if="check_has_permission('viewPatientManagement')"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-sm="3"
        vs-xs="12"
      >
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Patients</div>
          </template>
          <h1 class="count">{{ patients }}</h1>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      admins: 0,
      clinics: 0,
      doctors: 0,
      nurses: 0,
      patients: 0,
      franchises: 0,
      alert: {
        active1: false,
        color: "success",
        title: "connected",
      },
      activeClinicId: "",
    };
  },
  methods: {
    ...mapActions("admin", ["getSuperAdminDashboardData"]),
    // ...mapActions("general", ["connectedToXeroSuccessfully"]),
    getAdminData() {
      const self = this;
      self.$vs.loading();
      this.getSuperAdminDashboardData(this.activeClinicId).then((res) => {
        self.$vs.loading.close();
        if (res.data.data.permissions) {
          this.$store.commit(
            "UPDATE_USER_PERMISSION",
            res.data.data.permissions
          );
        }
        self.clinics = res.data.data.clinics;
        self.doctors = res.data.data.doctors;
        self.nurses = res.data.data.nurses;
        self.patients = res.data.data.patients;
        self.franchises = res.data.data.franchises;
      });
    },
  },
  watch: {
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activeClinicId = newVal;
        this.getAdminData();
      }
    },
  },
  async created() {
    this.activeClinicId = await this.$store.state.AppActiveClinicId;
    // const responseQuery = this.$route.query.response;
    let self = this;
    // if (responseQuery) {
    //   self.connectedToXeroSuccessfully();
    //   let res = self.$route.query.response;
    //   let query = Object.assign({}, self.$route.query);
    //   delete query.response;
    //   self.$router.replace({ query });
    //   if (res === "successfully-connected")
    //     self.alert = {
    //       active1: true,
    //       color: "success",
    //       title: "Success",
    //       message: "Successfully connected to xero."
    //     };
    //   else if (res === "already-connected")
    //     self.alert = {
    //       active1: true,
    //       color: "danger",
    //       title: "Info",
    //       message: "It seems another admin has already completed this task."
    //     };
    // }

    await setTimeout(function () {
      self.getAdminData();
    }, 2000);
  },
};
</script>
<style>
.count {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}
.heading {
  font-size: large;
  font-weight: 600;
  color: black;
  /* margin-top: -5px;
     margin-bottom: -5px;*/
}
</style>
